@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.container{
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
}
.main_container{
    width: 100vw;
    /* border-radius: 5px; */
    /* padding: 10px; */
    height: 70vh;
    display: flex;
    /* background-color: antiquewhite; */
    /* justify-content: space-between; */
}
.right_child{
    width: 30%;
    background-color: white;
    display: flex;
    flex-direction: column;
}
.header_text{
    color: #645CAA;
    font-size: 2.5rem
}
.header{
  padding: 0px 40px;
  margin-top: 90px;
}
.header p{
    font-size: 0.9rem;
}
.form_container{
    padding: 30px 40px;
}
.input_field{
    padding: 18px;
    width: 350px;
    font-size: 1rem;
    border-radius: 2px;
    border: none;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3);
}
.input_field:focus{
    outline: none;
}
.loginSection{
    align-items: center;
    margin-left: 170px;
    margin-top: 25px;
}
.loginBtn {
    padding: 10px 30px;
    /* margin-right: 40px; */
    font-size: 1rem;
    background-color: #645CAA;
    border: none;
    color: white;
    border-radius: 3px;
    cursor: pointer;
}
.left_child{
    width: 62%;
    background-image: url('../../../assets/images/logos/register\ image.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    margin-left:80px;
}
.left_child_section{
   height: 100%;
   width: 100%;
   /* margin-left: 50px; */
}
.registerLinkSection{
    display: flex;
    /* margin-left: 100px; */
    margin: 15px 120px;
}
.signLink{
    text-decoration: underline;
    margin-left: 5px;
    cursor: pointer;
}
.signLink:hover{
    color: #645CAA;
}