/* .usercard{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
} */
.usercard_background{
    position: relative;
    height: 50%;
    width: 100%;
}
.background_profile{
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* position: relative; */

}
.profile{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 3px solid white;
    position: absolute;
}
.profile_detials{
    text-align: center;
    padding-top: 70px;
    width: 100%;

}
.userProfile{
    position: absolute;
    top: 60%;
    width: 100%;
    left: 20%;
}
.UserCard_para{
    font-size: 0.9rem;
border-bottom: 1px solid black;
/* padding-bottom: 10px; */
padding: 10px;

}
.UserCard_para2{
    padding: 10px;
}


