*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    
}
.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    align-items: center;

}
.flex_one{
    width: 50%;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3);
    /* margin: auto; */
}
.backgorundProfile{
    width: 100%;
    height: 250px;
    /* background-image: url('https://media.istockphoto.com/photos/lottery-picture-id95442265?b=1&k=20&m=95442265&s=170667a&w=0&h=fKu1DRGrbfXVXWHR5c0MlE2glGse5-l8DwoUrQH4UUA=');
    background-repeat: no-repeat;
    background-size: cover; */
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

}
.myprofileCard{
    position: relative;
}
.myprofile{
  width: 200px;
  /* position: absolute; */
  height: 200px;
  border: 5px solid white;
  border-radius: 50%;
}
.userprofile{
    /* padding-bottom: 400px; */
    left: 8px;
    top: 50%;
    text-align: center;
    position: absolute;
}
.myProfileBio{
   display: flex;
   justify-content: space-between;
   padding: 20px;
   padding-top: 80px;
}
.profileName{
    font-size: 1.2rem;
}
.profileBio{
    font-size: 0.8rem;
}
.editIcon{
    font-size: 1.5rem;
    cursor: pointer;
    
}
.flex_two{
    width: 50%;
    margin-top: 30px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3);
    /* margin: auto; */
}
.experienceHeader{
    display: flex;
    justify-content: space-between;
    padding: 20px;
}
.iconSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    
}
.editIcon:hover{
    background-color: #8682a6;
    border-radius: 50%;
}
.experience{
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
 
}
.experienceLogo{
    width: 60px;
    height: 60px;
    border-radius: 50px;
}
.experiencePara{
    font-size: 0.8rem;
}
.flex_three{
    width: 50%;
    margin-top: 30px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
}
.flex_four{
    width: 50%;
    margin-top: 30px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
}
.skillContent{
    padding: 10px 98px;
    /* border-bottom: 1px solid black; */
}
.line{
    width: 89%;
    margin: auto;
}
.connectionsInfo{
    color: #645CAA;
    cursor: pointer;
    font-weight: bold;
    width: 100px;
}
.connectionsInfo:hover{
    text-decoration: underline;
}