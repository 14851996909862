*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.sections{
    display: grid;
    place-items: center;
    /* width: 100vw; */
    margin-top: 30px;
    /* background-color: aliceblue; */
}
.flex_one{
    /* margin: auto; */
    width: 50%;
    /* background-color: antiquewhite; */
    border-radius: 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3);
}
.header{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: 0.8rem;
    border-bottom: 1px solid rgb(180, 167, 167);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.requestUserName{
    cursor: pointer;
}
.user_text{
    font-size: 0.8rem;

}

.acceptBtn{
    padding:6px 12px;
    border: none;
    /* border: 2px solid#645CAA; */
    background-color: #7069ac;
    font-weight: bold;
    color: white;
    cursor: pointer;
    font-size: 1rem;
}

.flex_tow{
    width: 50%;
    margin-top: 30px;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3);
}
.flex_tow_header{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: 0.9rem;
}
.newUserCard{
   margin: 0;
   max-width: 1000px;
   display: grid;
   grid-template-columns: repeat(3,1fr);
   gap: 8px;
}
.containerCards{
    margin: 10px;
    /* background-color: antiquewhite; */
    display: flex;
    /* height: 50%; */
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    border-radius: 15px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3);
}
.header_profileCard{
    position: relative;
    
}
.header_profile{
    width: 100%;
    /* height: 170px; */
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.NewUserCard_profilesection{
    left: 8px;
    top: 50%;
    text-align: center;
    position: absolute;
    
}

.NewUserCard_profile{
    height: 80px;
    width: 80px; 
    border-radius: 50px;
}
.NewUserCard_profileDetails{
    padding: 0px 8px;
    padding-top: 20px;
}
.NewUserCardName{
    font-size: 1rem;
}
.NewUserCardBio{
    font-size: 0.8rem;
}
.followSection{
    /* padding:15px 20px;  */
    display: flex;
    justify-content: center;
    padding: 15px;
    /* position: fixed; */
}
.followBtn{
    padding: 5px 20px;
    /* border-radius: 20px; */
    border:none;
    background-color: #7069ac;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    color: white;
}

.viewMoreFriendRequest{
    display: flex;
    justify-content: center;
    padding: 5px;
    color: #7b74b8;
    font-weight: bold;
    cursor: pointer;
}

.requsetProfile{
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.requestSectionli{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid rgb(167, 163, 163);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.requestSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}
.requestAcceptSection{
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 10px;
}
.ignoreBtn{
    padding: 10px;
    color: rgb(98, 97, 97);
    font-size: 1rem;
    cursor: pointer;
}