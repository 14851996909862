@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
.nav{
    background-color:#7069ac;
    display: flex;
    /* width: 100vw; */
    justify-content: stretch;
    /* position: fixed; */
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    gap: 2%;
    /* overflow: hidden; */
    z-index: 1;
    box-shadow: 2px 2px 5px 2px rgba(28, 27, 27, 0.3);
}
.logo{
    display: flex;
    gap: 12rem;
}
.logoTitle{
    box-shadow: 0px 2px 10px 6px rgba(185, 169, 169, 0.3);
    padding: 5px 10px ;
    border: 1px solid rgb(165, 162, 162);
    border-radius: 15px;
    color: #fba95d ;
    /* background-color: #7b7575; */

}
.logoname{
    margin-right: 20px;
    margin-left: 30px;
    /* padding: 0px 5px; */
    display: flex;
    align-items: center;
    /* background-color: aliceblue; */
}
.nav ul{
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    /* padding-right: 18px; */
    /* gap: 2%; */
}
.nav .navLink{
    text-decoration: none;
    height:100%;
    display: flex;
    align-items: center;
    padding: 1.3rem ;
    /* padding-left: 1rem; */
    color: white;
    font-weight: bold;
    font-size: 1rem;
}
.navicon{
    margin-right:5px;
    /* font-size: 1.5rem; */
    /* padding-right: 5px; */
}
.searchBar{
    /* align-items: center; */
    padding: 1.2rem 0rem;
}
.searchBtn{
    background-color: white;
    /* padding: 5px 15px; */
    border-radius: 5px;
    display: flex;
    align-items: center;
    /* gap: 2%; */
    width: 250px;
    padding: 5px;
    /* border: 1px solid black; */
}
h2{
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.search_filed{
    background-color: white;
    /* padding: 8px ; */
    width: 100%;
    border: none;
}
.search_filed:focus{
    outline: none;
}
.navLink.active{
    background-color: rgb(125, 33, 33);
    text-decoration: underline;
}
.navLink:hover{
    color: rgb(232, 224, 224);
}
.searchicon{
    cursor: pointer;
}
.navProfile{
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin: 5px;
    /* margin-left: 130px; */
}
.navuser{
    margin-left: 100px;
}
#navuserProfile{
    padding: 10px;
    color: #ff8381;
    /* color: #fba95d; */
    /* font-weight: bold; */
}