@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
.container{
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
}
.main_container{
    display: flex;
    /* justify-content: space-between; */
    width: 100vw;
    height: 75vh;
    padding: 10px;
    margin-top: 70px;
    /* background-color: antiquewhite; */
}
.left_child{
    width: 60%;
    background-image:url('../../../assets/images/logos/login\ image.png') ;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 150px;
}
.right_child{
    width: 40%;
    margin-right: 20%;
}
.header{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
}
.header_text{
    font-size: 2rem;
    color: #645CAA;
}
.registe_container{
   margin: 50px 110px;
}
.input_container{
    background-color: white;
    padding: 18px;
    width: 350px;
    border-radius: 3px;
    border: none;
    font-size: 1rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3);
}
.input_container:focus{
    outline: none;
}
.lebel_text{
    font-size: 0.9rem;
    font-weight: bold;
}
.regd_btn{
    padding: 12px;
    width: 150px;
    border-radius: 3px;
    margin-top: 40px;
    margin-left: 100px;
    background-color: #645CAA;
    color: white;
    border: none;
    font-size: 1rem;
    cursor: pointer;
}
.error{
    font-size: 0.8rem;
    font-weight: bold;
    color: red;
}

.back_text{
    /* font-size: 0.8rem; */
    /* margin:30px 110px ; */
    margin-left: 130px;
    margin-top: 20px;
    text-decoration: underline;
    cursor: pointer;
}
.back_text:hover{
    color: #645CAA;
}