*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* background-color: aliceblue; */
}

section{
    display: flex;
    justify-content: center;
    width: 70vw;
    margin: auto;
    gap: 5%;
    padding: 20px;
}
.flex_two{
    flex: 1.8;
}
.flex_one{
    flex: 0.6;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* min-height: 50vh; */
    position: -webkit-sticky;
    position: sticky;
    top: 12%;
    height: 50%;
}

.postProfile{
    width: 50px;
    height: 50px;
    border-radius: 50px;
}
.userPostSection{
    /* border: 1px solid rgb(116, 113, 113); */
    border-radius: 10px;
    /* background-color: rgb(106, 106, 179,0.5); */
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3);
}
.post_header{
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    gap: 2%;

}
.postButton{
    padding:4px 5px;
    /* border-color: rgb(145, 142, 142); */
}
.postBtn{
    width: 590px;
    padding: 11px;
    border-radius: 20px;
    border: 1px solid rgb(105, 103, 103);
    cursor: pointer;
}
.postBtn:hover{
    background-color:rgb(200, 195, 195,0.4) ;
    /* background-color: rgb(124, 178, 38,03); */
    transition: all 0.3s;
}
.userPost_icon{
    display: flex;
    justify-content: space-between;
    padding: 5px 30px;
    /* align-items: center; */
}
.icon_div{
    display: flex;
    cursor: pointer;
    gap: 5px;
    align-items: center;
    font-size: 0.8rem;
}
.icons{
    font-size: 1.5rem;
}
.userFeedsection{
    /* border:  1px solid black;  */
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3);
}
.feed_profile{
    width: 50px;
    height: 50px;
    border-radius: 50px;
}
.userFeed_profile{
    display: flex;
    /* justify-content: space-between; */
    padding: 15px 15px;
    gap: 2%;
}
.feedProfileDetails{
    color: rgb(154, 149, 149);
    font-size: 0.8rem;
}
.feed_postImage{
    min-height: 500px;
    width: 100%;
}
.feedtext{
    padding: 0px 30px;
}
.feedDownLine{
    width: 40vw;
    border-bottom: 1px solid rgb(173, 171, 171,0.5);
    align-items: center;
    margin: auto;
    padding-top: 5px;
}
.comment_div{
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
}
.commentSection{
    display: flex;
    /* justify-content: center; */
    cursor: pointer;
    font-size: 0.8rem;
    gap: 2%;
    align-items: center;
}
.commentSection_icon{
    font-size: 1.5rem;
}
.read_more_less_more:hover{
    text-decoration: underline;
    color: #645CAA;
}
.read_more_less_less:hover{
    text-decoration: underline;
    color: #645CAA;
}