.modalBackground{
    /* width: 100vw;
    height: 100vh; */
    /* background-color: rgba(93, 90, 90, 0.6); */
    background-color: rgba(75, 73, 73, 0.75);
    /* position: fixed; */
    display: flex;
    justify-content:center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    height: 100vh;
    width: 100vw;
}
.modalContainer{
    width: 550px;
    /* min-height: 500px; */
    border-radius: 12px;
    background-color: white;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.35);
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
}

.title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(229, 226, 226);
    padding: 10px;
    font-size: 1.3rem;
}
.modalCloseBtn{
    cursor: pointer;
}

.textarea{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 70px;
}
.bodyText{
    color:#645CAA ;
    font-weight: bold;
    cursor: pointer;
    /* background-color: antiquewhite; */
}
.imageInput{
    display: none;
}
.footer{
    display: flex;
    justify-content:flex-end;
    align-items: center;
    padding: 0 8px;
}
.modalposticon{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.modalPostBtn{
    padding: 8px 35px;
    /* color: black; */
    /* border-radius: 15px; */
    border: none;
}
.modalPostBtn:hover{
    background-color:#645CAA ;
    font-weight: bold;
    cursor: pointer;
    color: white;
}
