.modalBackground{
    /* width: 100vw;
    height: 100vh; */
    /* background-color: rgba(93, 90, 90, 0.6); */
    background-color: rgba(0,0,0,.75);
    /* position: fixed; */
    display: flex;
    justify-content:center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    height: 100vh;
    width: 100vw;
}
.modalContainer{
    width: 550px;
    /* min-height: 500px; */
    border-radius: 12px;
    background-color: white;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.35);
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
}
.ProfileImage{
    width: 50px;
    height: 50px;
    border-radius: 50px;
}
.modalInput{
    display: none;
}
.title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(186, 181, 181);
    padding: 10px;
}
.modalCloseBtn{
    cursor: pointer;
}
.modalProfile{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%;
    padding: 10px;

}
.textInput{
    padding: 8px 25px;
}
textarea{
    min-height: 100px;
    width: 100%;
    resize: none;
}
.modalTextarea{
    border: none;
    background: transparent;
    width: 100%;
}
.modalTextarea:focus{
    outline: none;
}

.hash_div{
    /* color: #645CAA; */
    /* font-weight: bold; */
    /* width: 170px; */
    padding: 1px 24px;
    /* padding: 10px; */
}

.footer{
    display: flex;
    justify-content:space-between;
    align-items: center;
    /* padding: 10px; */
}
.modalposticon{
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 2rem; */

}
.modalposticonDiv{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 35px;
    /* padding: 5px 28px; */
}
.icons{
    font-size: 1.6rem;
    cursor: pointer;
}
.playIcon{
    font-size: 1.8rem;
    cursor: pointer;
}
.modalPostBtn{
    padding: 8px 35px;
    font-weight: bold;
    /* color: rgb(152, 149, 149); */
    background-color:#645CAA ;
    border: none;
    cursor: pointer;
}
/* .modalPostBtn:hover{
    background-color:#645CAA ;
} */
/* .uploadImage{
    text-align: center;
} */
.uploadImage{
    width: 100%;
    padding: 9px 25px;
    /* min-height: 500px; */
    /* max-height: 800px; */
}
.uploadBtn{
    padding:0 30px;
    color: #645CAA;
    font-weight: 600;
    border: 1px solid #645CAA;
    /* background-color: #645CAA; */
}