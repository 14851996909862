*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.container{
    width: 100vw;
    /* background-color: aliceblue; */
    /* height: 100vh; */
    display: flex;
    justify-content: center;
}
.header{
   margin-left: 20%;
   margin-top: 3%;
   padding: 5px;
   color: rgb(133, 138, 138);
}
.main_container{
    background-color: white;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3);
    border-radius: 10px;
    width: 60vw;
    /* height: 60vh; */
    /* margin-top: 4%; */
}
.friendListProfile{
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.friendlistSection{
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 20px 10px;
}
.endline{
    color: rgb(233, 226, 226);
    width: 87%;
    margin-left: 120px;
}
.friendlistBio{
    font-size: 0.9rem;
}
.friendlistaddress{
    font-size: 0.9rem;
}
.userName{
    cursor: pointer;
}