
.container{
    display: grid;
    place-items: center;
    /* background-color: rgb(170, 176, 181); */
    border-radius: 15px;
    width: 20%;
    margin: auto;
    margin-top: 20px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3);
}
.header_profile{
    width: 100%;
    height: 150px;
    background-image: url('https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

}

/* .background_pic{
    height: 150px;
} */
.NewUserCard_profilesection{
    position: absolute;
    padding-bottom: 55px;
    padding-right: 200px;
}
.NewUserCard_profile{
    height: 80px;
    width: 80px; 
    border-radius: 50px;
}
.NewUserCard_profileDetails{
   margin-right: 70px;
}
.NewUserCardName{
    font-size: 1.2rem;
    padding-top: 25px;
}
.NewUserCardBio{
    font-size: 0.8rem;
}
.followSection{
    padding:15px 0px; 
}
.followBtn{
    padding: 5px 110px;
    border-radius: 20px;
    border: 3px solid#645CAA;
    background-color: white;
    font-size: 1rem;
}