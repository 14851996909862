*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* background-color: rgb(165, 169, 173); */
}
.containers{
    width: 20%;
    /* margin-top: 20px; */
    background-color:rgb(233, 236, 237);
    margin: 20px 100px;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* height: 500px; */
    border-radius: 10px;
}
.main_containers{
    width: 100%;
    margin: auto;
    text-align: center;
}
.background_image{
    width: 100%;
    /* border-radius: 10px; */
    /* border-top-right-radius: 5px; */
    /* position: absolute; */
}
img{
    height: 200px;
    width: 100%;
}
.userProfile > .profile{
    border-radius: 50%;
    height: 80px;
    width: 80px;
    /* position: absolute; */
    margin: auto;
    border: 3px solid #fff;
    display: flex;
    z-index: 1;
}
.background_profile{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}