
.container{
    background-color: rgb(227, 223, 223);
    width: 35vw;
    margin: auto;
    /* display: flex; */
    border-radius: 5px;
}
.main_container{
    display: flex;
    justify-content:flex-start;
    gap: 2%;
    align-items: center;
    padding: 5px;
}
.img{
    width: 50px ;
    height: 50px;
    border-radius: 50px;
}
.postBtn{
    background-color: rgb(212, 220, 228);
    border: 1px solid rgb(114, 112, 112);
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 50px;
    width: 100%;
    text-align: left;
    
}

.last_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    /* gap: 1%; */
}
.last_container div{
    font-size: 0.8rem;
    
}
.icons{
    font-size: 1rem;
    /* padding: 50px; */
    /* padding-top: 35px; */
}
.icon_div{
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}