*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}
.container{
    width: 100vw;
    height: 100vh;
    background-color:rgb(222, 222, 218);
    position: relative;
    display: flex;

}
.header_line{
    width: 40vw;
    position: absolute;
    left: 50%;
    top: 20%;
    border-bottom: 2px solid rgb(154, 151, 151);
    transform: translate(-50%,-50%);
}
.child_container{
    width: 40vw;
    /* position: absolute; */
    /* display: flex; */
    height: auto;
    justify-content: center;
    margin: auto;
    margin-top: 170px;
    align-items: center;
    /* left: 50%;
    top: 20%;
    transform: translate(-50%,-50%); */
    background-color: white;
    border-radius: 5px;
}
.feed_profile{
    width: 60px;
    height: 60px;
    border-radius: 50px;
}
.feed_profileSection{
    display: flex;
    justify-content:stretch;
    align-items: center;
    gap: 2%;
    padding: 10px;
}
.feed_postImage{
    width: 100%;
    /* height: 400px; */
}
.feed_downLine{
    border-bottom: 1px solid rgb(119, 117, 117);
    width: 38vw;
    margin: auto;
}
.comment_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
}
.commentSection  {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}  
