
.dropdown{
    /* width: 8%; */
    margin: 0 auto;
    display: flex;
    justify-content: center;
    /* padding-top: 5px; */
    align-items: center;
    user-select: none;
    position: absolute;
    margin-left: 35px;
    /* background-color: antiquewhite; */

}

.dropdown .dropdown_btn{
    padding: 10px;
    /* color: white; */
    /* background-color: rgb(116, 117, 118); */
    cursor: pointer;
}

.dropdown .dropdown_content{
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3);
    top: 120%;
    right:0px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color:white;
    width: 200px;
    position: absolute;

}
.dropdown .dropdown_content .dropdown_item{
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 0.9rem;
    color: rgb(82, 83, 83);
    /* align-items: center; */
}
.logout_btn:hover{
    /* background-color: #eae7e7; */
    text-decoration: underline;
    color: red;
    font-weight: bold;

}
.viewProfile{
    background-color:white;
    border: 2px solid rgb(172, 163, 163);
    width: 160px;
    border-radius: 20px;
    padding:5px;
    font-size: 0.9rem;
    color: rgb(98, 94, 94);
    /* color: rgb(39, 107, 203); */
    font-weight: bold;
}
.viewProfile:hover{
    border: 2px solid #645CAA;
    color: #645CAA;
}
